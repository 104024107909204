<template>
    <div class="warp">
        <div class="layout">
            <div class="box">
                <div class="title">
                    <h1>请输入会员账号</h1>
                    <div class="close" @click="close()"></div>
                </div>
                <div class="form">
                    <span>会员账号：</span>
                    <div>
                        <input
                            type="text"
                            v-model="loginForm.username"
                            placeholder="请在此输入会员账号"
                            autocomplete="off"
                        />
                        <a @click="doLogin()">确定</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            loginForm: {
                action: 'login',
                username: ''
            }
        }
    },
    methods: {
        async doLogin() {
            if (this.loginForm.username.replace(/\s*/g, '') === '') {
                alert('请输入会员账号')
                return false
            }

            const { data } = await this.$axios.get('ajax.php', { params: this.loginForm })
            // console.log(data)
            alert(data.msg)

            if (data.stat === 1) {
                this.$store.commit('setUserData', {
                    username: this.loginForm.username,
                    score: parseInt(data.score)
                })
            }

            this.close()
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="less" scoped>
.warp {
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    .layout {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }
    .box {
        position: relative;
        width: 506px;
        margin: 0 auto;
        background: #960001;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }
    .title {
        position: relative;
        height: 34px;
        background: url("../assets/images/t1_1.jpg") center top no-repeat;
        h1 {
            color: #960001;
            font-size: 16px;
            line-height: 34px;
        }
        .close {
            position: absolute;
            right: 0;
            top: 0;
            width: 34px;
            height: 34px;
            background: url("../assets/images/s3.png") center no-repeat;
            background-size: cover;
            cursor: pointer;
        }
    }
    .form {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
        border: solid 1px #be3839;
        div {
            display: flex;
            justify-content: center;
        }
        span {
            color: #fff;
        }
        input {
            background-color: #750606;
            border: solid 1px #eb0002;
            width: 210px;
            padding: 0 10px;
            color: #fff;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            outline: none;
        }
        a {
            width: 74px;
            height: 32px;
            line-height: 32px;
            background: url("../assets/images/s2.jpg") center no-repeat;
            cursor: pointer;
        }
    }
}
</style>
