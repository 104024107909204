<template>
    <div class="warp">
        <div class="layout">
            <div class="box">
                <div class="title">
                    <h1>查询中心</h1>
                    <div class="close" @click="close()"></div>
                </div>
                <div class="content">
                    <div class="form">
                        <span>会员账号：</span>
                        <div>
                            <input
                                type="text"
                                v-model="searchForm.username"
                                placeholder="请在此输入会员账号"
                                autocomplete="off"
                            />
                            <a @click="doSearch()">查询</a>
                        </div>
                    </div>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>奖品金额</th>
                                    <th>中奖时间</th>
                                    <th>是否派彩</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in list" :key="index">
                                    <td>{{ item.prize }}</td>
                                    <td>{{ item.time }}</td>
                                    <td>{{ item.state }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <Paginate
                            :page-count="count"
                            :click-handler="clickCallback"
                            :prev-text="'上一页'"
                            :next-text="'下一页'"
                            :container-class="'ipage'"
                            v-if="paginateIsShow"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
    name: 'search',
    components: {
        Paginate
    },
    data() {
        return {
            searchForm: {
                action: 'search',
                username: '',
                currentPage: 1
            },
            count: 0,
            list: [],
            paginateIsShow: false
        }
    },
    methods: {
        async doSearch() {
            if (this.searchForm.username.replace(/\s*/g, '') === '') {
                alert('请输入会员账号')
                return false
            }

            const { data } = await this.$axios.get('ajax.php', { params: this.searchForm })

            if (data.count > 5) {
                this.paginateIsShow = true
            }
            this.count = data.count
            this.list = data.list
        },
        clickCallback(page) {
            this.searchForm.currentPage = page
            this.doSearch()
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="less" scoped>
.warp {
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    .layout {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }
    .box {
        position: relative;
        width: 506px;
        margin: 0 auto;
        background: #960001;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }
    .title {
        position: relative;
        height: 34px;
        background: url("../assets/images/t1_1.jpg") center top no-repeat;
        h1 {
            padding-left: 10px;
            color: #960001;
            font-size: 16px;
            line-height: 34px;
            text-align: left;
        }
        .close {
            position: absolute;
            right: 0;
            top: 0;
            width: 34px;
            height: 34px;
            background: url("../assets/images/s3.png") center no-repeat;
            background-size: cover;
            cursor: pointer;
        }
    }
    .content {
        border: solid 1px #be3839;
    }
    .form {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        div {
            display: flex;
            justify-content: center;
        }
        span {
            color: #fff;
        }
        input {
            background-color: #750606;
            border: solid 1px #eb0002;
            width: 210px;
            padding: 0 10px;
            color: #fff;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            outline: none;
        }
        a {
            width: 74px;
            height: 32px;
            line-height: 32px;
            background: url("../assets/images/s2.jpg") center no-repeat;
            cursor: pointer;
            color: #960001;
        }
    }
    .table {
        width: 100%;
        padding: 0 10px 10px 10px;
        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
        }
        th {
            background: #700001;
            color: #f1f3b3;
            line-height: 32px;
            text-align: center;
            font-size: 15px;
            border: 1px solid #dd0002;
        }
        td {
            border: 1px solid #dd0002;
            font-size: 14px;
            color: #f1f3b3;
            line-height: 32px;
            text-align: center;
        }
    }
}
.table /deep/ .ipage {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    a {
        padding: 5px 10px;
        background: #700001;
        border: 1px solid #dd0002;
        color: #f1f3b3;
    }
    .disabled a {
        opacity: 0.6;
        cursor: default;
    }
    .active a {
        background: #fef592;
        border-color: #fef592;
        color: #700001;
    }
}
</style>
