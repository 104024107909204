<template>
    <div class="wrap">
        <div class="layout">
            <div class="box" :class="{'opening':!isOpen}">
                <div class="close" @click="close()"></div>
                <div class="top" v-if="isOpen">
                    <img :src="logo" alt />
                    <p>878hb.cc</p>
                    <h1>
                        您还有
                        <span>{{ score }}</span>
                        次机会
                    </h1>
                    <div
                        class="open"
                        :class="{'avtive':isActive}"
                        v-points="600"
                        @click="start()"
                    >拆红包</div>
                </div>
                <div class="over" v-else>
                    <img :src="logo" alt />
                    <div class="prize">
                        <h1>恭喜您获得</h1>
                        <h2>{{ pirze }}元</h2>
                        <p>系统将在30分钟内派送到您的账户内，请耐心等待……</p>
                    </div>
                </div>
                <div class="foot">878hb.cc</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pack',
    props: {
        logo: {
            type: String,
            default: null
        },
        username: {
            type: String,
            default: null
        },
        score: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            isActive: false,
            pirze: 0,
            isOpen: true
        }
    },
    methods: {
        async start() {
            this.isActive = true
            // 获取奖品
            const { data } = await this.$axios.post('bonus.php', { username: this.username })
            // console.log(data)
            if (data.stat !== 0) {
                alert(data.msg)
                return false
            }
            this.pirze = data.prize
            this.$store.commit('setUserData', {
                username: this.username,
                score: parseInt(data.score)
            })
            this.isOpen = false
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="less" scoped>
.wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    .layout {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }
    .box {
        position: relative;
        width: 359px;
        height: 520px;
        margin: 0 auto;
        background: #ae3727;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
    }
    .top {
        position: relative;
        height: 380px;
        padding-top: 50px;
        background: url("../assets/images/p1.png") center top no-repeat;
        p {
            padding: 30px 0;
            color: #fff;
            font-size: 18px;
        }
        h1 {
            color: #fff;
            span {
                color: #ffb72c;
            }
        }
    }
    .open {
        position: absolute;
        bottom: -61px;
        left: 50%;
        margin-left: -61px;
        width: 122px;
        height: 122px;
        line-height: 122px;
        text-align: center;
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        border-radius: 50%;
        background: #ffb72c;
        cursor: pointer;
    }
    .foot {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        line-height: 50px;
        color: #ffb72c;
        font-size: 16px;
    }
    .close {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 19px;
        height: 17px;
        z-index: 10;
        background: url("../assets/images/p4.png") no-repeat;
        cursor: pointer;
    }
    .opening {
        background: #fff;
        .foot {
            color: #333;
        }
    }
    .over {
        height: 100%;
        padding-top: 40px;
        background: url("../assets/images/p2.png") no-repeat;
    }
    .prize {
        padding: 80px 20px 0 20px;
        h1 {
            color: #aaa;
            text-align: center;
            font-size: 24px;
            font-weight: 400;
        }
        h2 {
            font-size: 26px;
            line-height: 80px;
            color: #e24830;
        }
        p {
            font-size: 16px;
            line-height: 30px;
        }
    }
}

@-webkit-keyframes routey {
    0% {
        -webkit-transform: rotateY(0deg);
    }
    25% {
        -webkit-transform: rotateY(90deg);
    }
    55% {
        -webkit-transform: rotateY(180deg);
    }
    75% {
        -webkit-transform: rotateY(90deg);
    }
    100% {
        -webkit-transform: rotateY(0deg);
    }
}
@-moz-keyframes routey {
    0% {
        transform: rotateY(0deg);
    }
    25% {
        transform: rotateY(90deg);
    }
    55% {
        transform: rotateY(180deg);
    }
    75% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}
@-ms-keyframes routey {
    0% {
        transform: rotateY(0deg);
    }
    25% {
        transform: rotateY(90deg);
    }
    55% {
        transform: rotateY(180deg);
    }
    75% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.avtive {
    animation: routey 0.8s infinite linear;
    -webkit-animation: routey 0.8s infinite linear;
}
</style>
