<template>
    <div class="main">
        <div class="header">
            <div class="wrapper layout">
                <a href class="logo">
                    <img :src="logo" alt />
                </a>
                <ul>
                    <template v-for="(item, index) in menus">
                        <li v-if="item.type === '0'" :key="index">
                            <a @click="searchIsShow = true">中奖查询</a>
                        </li>
                        <li v-else :key="index">
                            <a target="_blank" :href="item.url">{{ item.name }}</a>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="bg1"></div>
        <div class="bg2"></div>
        <div class="status">
            <div class="wrapper">
                <CountDown
                    :times="countDownData.times"
                    :title="countDownData.title"
                    @start="state()"
                />
                <div class="openBtn" @click="open()" v-points="600"></div>
            </div>
        </div>
        <div class="scroll">
            <div class="box">
                <div class="title"></div>
                <div class="lists">
                    <div class="list">
                        <vueSeamless :data="scroll.list1" :class-option="scroll.option">
                            <p v-for="(item, index) in scroll.list1" :key="index">
                                <span class="name">
                                    恭喜会员
                                    <font>{{ item.name }}</font>
                                </span>
                                <span>
                                    抢中红包
                                    <font>{{ item.prize }}</font>
                                </span>
                                <span>{{ item.times }}</span>
                            </p>
                        </vueSeamless>
                    </div>
                    <div class="list">
                        <vueSeamless :data="scroll.list2" :class-option="scroll.option">
                            <p v-for="(item, index) in scroll.list2" :key="index">
                                <span class="name">
                                    恭喜会员
                                    <font>{{ item.name }}</font>
                                </span>
                                <span>
                                    抢中红包
                                    <font>{{ item.prize }}</font>
                                </span>
                                <span>{{ item.times }}</span>
                            </p>
                        </vueSeamless>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="wrapper">
                <div class="tit1"></div>
                <div class="box">
                    <p>
                        <a href="http://878e.cc/" target="_blank">【22彩票】</a>发红包雨拉~~~~人人都能领取！每天准时开抢！惊喜不断~~~
                    </p>
                    <p>开放时间：每天北京时间凌晨02:00:00至23:59:59分开放。</p>
                    <p>活动条件：当日有效存款总额达到100元以上，次日02:00:00至23:59:59期间即可参与红包雨活动，抽奖次数根据存款金额等级而获得，完全随机中奖。</p>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>日存款</th>
                                    <th>次数</th>
                                    <th>日存款</th>
                                    <th>次数</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>≥100元</td>
                                    <td>1次</td>
                                    <td>≥3000元</td>
                                    <td>2次</td>
                                </tr>
                                <tr>
                                    <td>≥5000元</td>
                                    <td>3次</td>
                                    <td>≥10000元</td>
                                    <td>4次</td>
                                </tr>
                                <tr>
                                    <td>≥50000元</td>
                                    <td>5次</td>
                                    <td>≥100000元</td>
                                    <td>7次</td>
                                </tr>
                                <tr>
                                    <td>≥300000元</td>
                                    <td>9次</td>
                                    <td>≥500000元</td>
                                    <td>12次</td>
                                </tr>
                                <tr>
                                    <td>≥1000000元</td>
                                    <td>15次</td>
                                    <td>≥3000000元</td>
                                    <td>20次</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        <span>◆奖品内容◆</span>
                    </p>
                    <p>现金红包，iPhone XS（256G）更多奖品持续更新中.......</p>
                    <p>
                        随手一抢中奖100%，奖了还不够？没关系！凡昨日存款总额 100 以上的会员，抽中我司特定幸运数值，即可申请相对应的转运彩金！
                        <a
                            href="http://878e.cc/"
                            target="_blank"
                        >【22彩票】</a>实力狂欢赠送，感恩有你，福运连绵！
                    </p>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>活动对象</th>
                                    <th>幸运数值</th>
                                    <th>转运金</th>
                                    <th>活动对象</th>
                                    <th>幸运数值</th>
                                    <th>转运金</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowspan="12">昨日存款总额100元+</td>
                                    <td>1.23</td>
                                    <td>12</td>
                                    <td rowspan="12">昨日存款总额1000元+</td>
                                    <td>1.23</td>
                                    <td>13</td>
                                </tr>
                                <tr>
                                    <td>2.34</td>
                                    <td>22</td>
                                    <td>2.34</td>
                                    <td>33</td>
                                </tr>
                                <tr>
                                    <td>3.45</td>
                                    <td>36</td>
                                    <td>3.45</td>
                                    <td>78</td>
                                </tr>
                                <tr>
                                    <td>4.56</td>
                                    <td>76</td>
                                    <td>4.56</td>
                                    <td>93</td>
                                </tr>
                                <tr>
                                    <td>6.78</td>
                                    <td>158</td>
                                    <td>6.78</td>
                                    <td>188</td>
                                </tr>
                                <tr>
                                    <td>7.89</td>
                                    <td>328</td>
                                    <td>7.89</td>
                                    <td>518</td>
                                </tr>
                                <tr>
                                    <td>1.11</td>
                                    <td>111</td>
                                    <td>1.11</td>
                                    <td>128</td>
                                </tr>
                                <tr>
                                    <td>2.22</td>
                                    <td>222</td>
                                    <td>2.22</td>
                                    <td>328</td>
                                </tr>
                                <tr>
                                    <td>3.33</td>
                                    <td>333</td>
                                    <td>3.33</td>
                                    <td>518</td>
                                </tr>
                                <tr>
                                    <td>6.66</td>
                                    <td>666</td>
                                    <td>6.66</td>
                                    <td>688</td>
                                </tr>
                                <tr>
                                    <td>8.88</td>
                                    <td>888</td>
                                    <td>8.88</td>
                                    <td>988</td>
                                </tr>
                                <tr>
                                    <td>9.99</td>
                                    <td>999</td>
                                    <td>9.99</td>
                                    <td>1588</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        <span>【注】</span>满足存款条件的会员，砸中之后，其中只要单个红包中金额为幸运数值即可申请，若出现重复数值视为一次，每天仅限申请一次，次日视为自动放弃！
                    </p>
                    <p>
                        <span>【注】</span>满足存款条件的会员，砸中之后，其中只要单个红包中金额为幸运数值即可申请，若出现重复数值视为一次，抽中豹子数值按最高数值计算，每天申请次数仅限一次，逾期视为自动放弃！
                    </p>
                    <p>范例：昨日存款总额10000以上的会员，砸到单个金额为1.23+3.45+7.89即可申请转运礼包：13+78+518=609元！</p>
                    <p>昨日存款总额10000以上的会员，砸到单个金额为1.11+1.11（重复数值计算一次）+9.99（豹子数值按最高计算）即可申请转运礼包128+1588=1716元！</p>
                </div>
                <div class="tit2"></div>
                <div class="box">
                    <p>1.每天02:00:00至23:59:59期间开始参与，且需在当天完成砸金蛋，逾期则视为自动放弃活动资格；</p>
                    <p>2.所获得奖金只需1倍流水，即可申请提款。</p>
                    <p>3.此活动不包含香港六合彩。转运彩金每天只能挑选一个金额申请一次。</p>
                    <p>4.获得中奖加赠要求的玩家，请在次日00:00:00跟在线客服申请，逾期则视为自动放弃！</p>
                    <p>5.凡是抽中“现金筹码”的玩家，无需申请，系统立即自动添加到中奖玩家账号上，如超过5分钟未派送，直接联系在线客服受理查询即可。</p>
                    <p>6.凡是抽中“iPhone XS”（颜色随机，256G，国行）的实物奖品的玩家，奖品不可折现；请务必于中奖后48小时内联系专员QQ【9996222】申请，提供快递收货地址、姓名及联系电话，奖品将在中奖玩家提供具体收货地址后十个工作日内寄出，请耐心等待。如中奖后48小时内未联系我司客服确认收货地址或因个人提供的收件信息不完整、不正确、电话无法联系上导致物品无法寄达，快递退回的情况，均视为自动放弃且不再安排寄送，请勿与客服就此理论。</p>
                    <p>7.此活动为【22彩票】系统程序自动运行，获奖的概率完全遵循力学及自然概率，不涉及任何人工操作，抽奖活动以系统判定为准，不得争议。</p>
                </div>
                <div class="tit3"></div>
                <div class="box">
                    <p>1. 所有优惠以人民币(CNY)为结算金额，以美东时间(EDT)为计时区间；</p>
                    <p>2. 每位玩家﹑每户﹑每一住址 、每一电子邮箱地址﹑每一电话号码﹑相同支付方式(相同借记卡/信用卡/银行账户) 及IP地址只能享有一次优惠；若会员有重复申请账号行为，公司保留取消或收回会员优惠彩金的权利；</p>
                    <p>3. 22彩票的所有优惠特为玩家而设，如发现任何团体或个人，以不诚实方式套取红利或任何威胁、滥用公司优惠等行为，公司保留冻结、取消该团体或个人账户及账户结余的权利；</p>
                    <p>4. 若会员对活动有争议时，为确保双方利益，杜绝身份盗用行为，22彩票有权要求会员向我们提供充足有效的文件，用以确认是否享有该优惠的资质；</p>
                    <p>5. 当参与优惠会员未能完全遵守、或违反、或滥用任何有关公司优惠或推广的条款，又或我们有任何证据有任何团队或个人投下一连串的关联注单，籍以造成无论赛果怎样都可以确保可以从该存款红利或其他推广活动提供的优惠获利，22彩票城保留权利向此团队或个人停止、取消优惠或索回已支付的全部优惠红利。此外，公司亦保留权利向这些客户扣取相当于优惠红利价值的行政费用，以补偿我们的行政成本；</p>
                    <p>6. 22彩票保留对活动的最终解释权，以及在无通知的情况下修改、终止活动的权利，适用于所有优惠。</p>
                </div>
            </div>
        </div>
        <div class="footer">Copyright © 878hb.cc所有 Reserved</div>
        <RedPack
            :amount="15"
            :size="60"
            :speed="2"
            :wind="0"
            :opacity="0.8"
            :swing="10"
            :image="floatImg"
            :zIndex="null"
            :resize="true"
            v-points="600"
            v-if="redPacketIsShow"
            @open="open()"
        />
        <Pack
            :logo="logo"
            :username="username"
            :score="score"
            @close="packIsShow = false"
            v-if="packIsShow"
        />
        <Login @close="loginIsShow = false" v-if="loginIsShow" />
        <Search @close="searchIsShow = false" v-if="searchIsShow" />
        <Closed :close-str="closeInfo.str" v-if="closeInfo.isShow" />
    </div>
</template>

<script>
import Logo from 'assets/images/logo.jpg'
import RedPack from 'components/RedPack'
import Pack from 'components/Pack'
import CountDown from 'components/CountDown'
import Login from 'components/Login'
import Search from 'components/Search'
import Closed from 'components/Closed'
import vueSeamless from 'vue-seamless-scroll'

export default {
    name: 'pc',
    components: {
        RedPack,
        Pack,
        CountDown,
        Login,
        Search,
        vueSeamless,
        Closed
    },
    data() {
        return {
            floatImg: require('assets/images/redpack.png'),
            logo: Logo,
            redPacketIsShow: false,
            packIsShow: false,
            loginIsShow: false,
            searchIsShow: false,
            menus: [],
            countDownData: {
                times: 0,
                title: ''
            },
            scroll: {
                option: {
                    step: 1,
                    limitMoveNum: 9,
                    openTouch: false,
                    waitTime: 1,
                    direction: 1,
                    singleHeight: 30
                },
                list1: [],
                list2: []
            },
            closeInfo: {
                str: '',
                isShow: false
            }
        }
    },
    created() {
        this.getList()
    },
    mounted() {
        this.state()
    },
    methods: {
        // 检测活动状态
        async state() {
            const { data } = await this.$axios.get('ajax.php', { params: { action: 'status' } })
            // console.log(data)
            this.menus = data.menu
            this.countDownData.times = data.timer
            if (data.stat === 0) {
                // alert(data.title)
                this.closeInfo.str = data.title
                this.closeInfo.isShow = true
                return false
            }

            this.countDownData.title = data.title

            if (data.stat === 1) {
                this.redPacketIsShow = true // 显示红包雨
                return false
            }
        },
        open() {
            if (this.username === '') {
                this.loginIsShow = true
                return false
            }

            if (this.score <= 0) {
                alert('没有机会了')
                return false
            }

            this.packIsShow = true
        },
        async getList() {
            const { data } = await this.$axios.get('ajax.php', { params: { action: 'winlist' } })
            // console.log(data)
            this.scroll.list1 = data.slice(0, 19)
            this.scroll.list2 = data.slice(20, 41)
        }
    },
    computed: {
        username() {
            return this.$store.state.userData.username
        },
        score() {
            return this.$store.state.userData.score
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    background: #d81521;
}
.wrapper {
    width: 1000px;
    margin: 0 auto;
}
.header {
    position: relative;
    z-index: 999;
    height: 84px;
    background: url("../../assets/images/header.jpg") #7c0000 center 0 no-repeat;
    .layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }
    ul {
        display: flex;
        align-items: center;
    }
    li {
        width: 123px;
        height: 46px;
        line-height: 46px;
        a {
            display: inline-block;
            width: 123px;
            height: 46px;
            color: #fbc373;
            font-size: 15px;
            text-align: center;
            font-weight: 700;
            cursor: pointer;
            &:hover {
                color: #fff;
                background: url("../../assets/images/navBg.png") no-repeat;
            }
        }
    }
}

.bg1 {
    height: 200px;
    background: url("../../assets/images/bg1.jpg") center top no-repeat;
}
.bg2 {
    height: 220px;
    background: url("../../assets/images/bg2.jpg") center top no-repeat;
}
.status {
    height: 230px;
    background: url("../../assets/images/bg3.jpg") center top no-repeat;
    .openBtn {
        width: 145px;
        height: 50px;
        margin: 20px auto 0 auto;
        background: url("../../assets/images/searchBtn.png") center top
            no-repeat;
        cursor: pointer;
    }
}
.scroll {
    height: 337px;
    padding-top: 60px;
    background: url("../../assets/images/bg4.jpg") center top no-repeat;
    .box {
        position: relative;
        width: 935px;
        height: 274px;
        margin: 0 auto;
        padding-top: 64px;
        background: url("../../assets/images/winBg.png") center top no-repeat;
    }
    .title {
        position: absolute;
        top: -63px;
        width: 100%;
        height: 112px;
        background: url("../../assets/images/winTit.png") center top no-repeat;
    }
    .lists {
        display: flex;
        justify-content: center;
    }
    .list {
        width: 450px;
        height: 180px;
        padding: 0 20px;
        overflow: hidden;
        p {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #fff;
            font {
                color: #ffcc00;
            }
            .name {
                display: inline-block;
                width: 110px;
                text-align: left;
            }
        }
    }
}

.content {
    background: #d81521;
    color: #fff;
    font-size: 14px;
    .wrapper {
        position: relative;
        left: -9px;
        width: 1198px;
        padding: 50px 0;
        background: #88000e;
    }
    .tit1 {
        width: 100%;
        height: 90px;
        padding: 20px 0;
        background: url("../../assets/images/t1.png") center no-repeat;
    }
    .tit2 {
        width: 100%;
        height: 90px;
        padding: 20px 0;
        background: url("../../assets/images/t2.png") center no-repeat;
    }
    .tit3 {
        width: 100%;
        height: 90px;
        padding: 20px 0;
        background: url("../../assets/images/t3.png") center no-repeat;
    }
    .box {
        width: 935px;
        margin: 0 auto;
        padding-bottom: 15px;
        p {
            line-height: 30px;
            text-align: left;
            span {
                color: #ff0;
            }
        }
    }
    .table {
        width: 100%;
        padding: 10px 0;
        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
        }
        th {
            border: solid 1px #ae1927;
            background: #f2c40f;
            height: 30px;
            color: #640404;
            font-weight: 400;
        }
        td {
            border: solid 1px #ae1927;
            height: 40px;
            color: #fbf6f7;
        }
    }
    a {
        color: #ff0;
    }
}

.footer {
    margin-top: 35px;
    padding-top: 35px;
    padding-bottom: 40px;
    text-align: center;
    font-size: 14px;
    color: #cf4243;
    background: #7c0000;
}
</style>
